import { Badge, EuiFlexGroup, EuiFlexItem, Icon } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { TaskProvider, useTaskContext } from '@app/precheck/Components/V2/context/TaskContext';
import { tabToPath } from '../Layout';
import { InboxItemContainer, NotificationBadge } from './SideNav.emotion';

export const STREAMS_LABEL = 'Portfolio';
export const SOV_LABEL = 'SOV';
export const STREAMS_PATH = 'streams';
export const SUBMISSIONS_LABEL = 'Client Accounts';
export const SUBMISSIONS_PATH = 'accounts';
export const ALERTS_CONFIG_PATH = 'alerts-config';
export const ALERTS_CONFIG_LABEL = 'Alerts Config';
export const PROJECTS_LABEL = 'Projects';
export const PROJECTS_PATH = 'projects';
export const USERS_LABEL = 'Users';
export const USERS_PATH = 'users';
export const PRECHECK_LABEL = 'Pre-Check';
export const PRECHECK_PATH = 'precheck';
export const HOME_PATH = 'home';
export const HOME_LABEL = 'Insights';
export const HUB_LABEL = 'Hub';
export const LIBRARY_PATH = 'library';
export const LIBRARY_LABEL = 'Library';
export const SUPPORT_PATH = 'support';
export const SUPPORT_LABEL = 'Support';
export const COPILOT_PATH = 'copilot';
export const COPILOT_LABEL = 'Copilot';
export const INBOX_LABEL = 'Inbox';
export const INBOX_PATH = 'inbox';
export const CASUALTY_LABEL = 'Casualty';
export const CASUALTY_PATH = 'casualty';
export const SPOTLIGHT_LABEL = 'Spotlight';
