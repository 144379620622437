import { FormCurrencyInput } from '@app/components/Form/FormCurrencyInput';
import { FormTextInput } from '@app/components/Form/FormTextInput';
import { ColumnType } from '@app/components/PropertiesGrid/types';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { EDIT_CELL_INPUT_KEY } from '../constants';

interface IEditFormFieldProps {
  type: ColumnType;
}

export const EditFormField = ({ type }: IEditFormFieldProps) => {
  const { stream } = useStreamContext();
  switch (type) {
    case 'currency':
    case 'currencyLocal':
      return (
        <FormCurrencyInput
          name={EDIT_CELL_INPUT_KEY}
          inputProps={{ prepend: <b>{stream?.displayCurrency}</b> }}
        />
      );

    default:
      return <FormTextInput name={EDIT_CELL_INPUT_KEY} />;
  }
};
