import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useLazyQuery, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import useLocalStorage from '@app/hooks/useLocalStorage';
import useSessionStorage from '@app/hooks/useSessionStorage';
import { AllFeaturesFlags } from '@app/platform/SystemSettings/Flags/types';
import { GET_ORGANIZATION_PAGE } from '@app/queries/organizations/getOrganizationsPage';
import { GET_PROJECTS } from '@app/queries/organizations/getProjects';
import { IGraphQLOrganization, OrgSubmissionConfig } from '@app/queries/organizations/types';

export interface SelectedOrganizationSummary {
  id: string;
  name: string;
  enableRedirectOnSOVUpload: boolean;
  enabledFeatures?: Array<string>;
  enableAccountDashboard: boolean;
  orgLevel: string;
  enableSOVManager: boolean;
}

export interface OrgData {
  organization: IGraphQLOrganization;
  orgSubmissionConfig: OrgSubmissionConfig;
}

interface OrgVars {
  name: string;
}

const GET_ORGANIZATION = gql`
  query GetOrganization($name: String!) {
    organization(name: $name) {
      id
      name
      enableUploadPortal
      enableStreamDashboard
      enableRedirectOnSOVUpload
      enableSecondaryModifiers
      excludeFromReporting
      enableUnderwriterEdit
      enabledFeatures
      unitOfMeasurement
      orgLevel
      enableSOVManager
    }

    orgSubmissionConfig(input: { orgName: $name }) {
      enableDashboard
    }
  }
`;

interface SetOrgByNameOptions {
  onCompleted?: (newSelectedOrg: SelectedOrganizationSummary) => void;
}
export interface IUserSessionContext {
  selectedOrganization?: SelectedOrganizationSummary;
  setSelectedOrganization: (val: SelectedOrganizationSummary) => void;
  setOrgByName: (name: string, options?: SetOrgByNameOptions) => void;
}

interface RouteParams {
  organizationName: string;
}
export const UserSessionContext = createContext({} as IUserSessionContext);

export const UserSessionProvider = ({ children }: { children: any }) => {
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  const isDM = isAdmin || !!account?.permissions?.canViewProjects;
  const isNonAdminWithOrgs = account?.isNonAdminWithOrgs;
  const orgsQuery = isAdmin ? GET_ORGANIZATION_PAGE : GET_PROJECTS;

  const [selectedOrganization, setSelectedOrganization] =
    useSessionStorage<SelectedOrganizationSummary>(account, 'selectedOrganization', undefined);

  useEffect(() => {
    if (selectedOrganization) {
      // https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK#Global_Context
      // FIX ME
      // @ts-ignore
      if (window?.aptrinsic) {
        console.debug('.aptrinsic: set global context');
        const customEventData = {
          accountId: selectedOrganization?.id,
          accountName: selectedOrganization?.name,
        };

        for (const featureFlag of AllFeaturesFlags) {
          const found = selectedOrganization?.enabledFeatures?.find(
            (enabledFeature) => enabledFeature === featureFlag,
          );
          customEventData[`FEATURE_${featureFlag}`] = !!found;
        }

        // FIX ME
        // @ts-ignore
        window?.aptrinsic('set', 'globalContext', customEventData);
      }
    }
  }, [selectedOrganization]);

  const isOrgUndefined = selectedOrganization === undefined;

  const hasAccessToSelectedOrgID =
    isAdmin || account?.managedOrgs?.some((o) => o.org.id === selectedOrganization?.id);

  const hasAccessToSelectedOrgName =
    isAdmin || account?.managedOrgs?.some((o) => o.org.name === selectedOrganization?.name);

  const [getOrgs, { data, loading }] = useLazyQuery(orgsQuery, {
    onError: () => console.error('GraphQL error in UserSessionContext.'),
    variables: isAdmin
      ? {
          cursor: null,
          filters: [],
          pageSize: 1,
          sortBy: [],
        }
      : {},
  });

  const { refetch: getOrg } = useQuery<OrgData, OrgVars>(GET_ORGANIZATION, {
    onError: () => {
      // Error reporting for the console
      console.error('GraphQL error loading org in UserSessionContext.');
    },
    skip: true,
    variables: {
      name: '',
    },
  });
  const [orgName, setOrgName] = useLocalStorage(account, 'orgName', undefined);

  const setOrgByName = async (name, { onCompleted }: SetOrgByNameOptions = {}) => {
    if (account?.permissions?.admin || account?.isNonAdminWithOrgs) {
      setOrgName(name);
    }

    let newSummary: SelectedOrganizationSummary;
    if (account?.permissions?.admin) {
      const res = await getOrg({ name });

      if (res?.data) {
        const {
          organization: {
            id,
            name,
            enabledFeatures,
            enableRedirectOnSOVUpload,
            orgLevel,
            enableSOVManager,
          },
          orgSubmissionConfig,
        } = res.data;
        newSummary = {
          enableAccountDashboard: orgSubmissionConfig?.enableDashboard,
          enableRedirectOnSOVUpload,
          enableSOVManager,
          enabledFeatures,
          id,
          name,
          orgLevel,
        };
      }
    } else {
      const selectedOrg = account.managedOrgs.find((o) => o.org.name === name);

      // FIX ME
      // @ts-ignore
      newSummary = {
        ...selectedOrg.org,
        enableAccountDashboard: selectedOrg.org.enableSubmissionDashboard,
        // FIX ME
        // @ts-ignore
        enableRedirectOnSOVUpload: !!selectedOrg.org.enableRedirectOnSOVUpload,
        // FIX ME
        // @ts-ignore
        enableSOVManager: selectedOrg.org.enableSOVManager,
      };
    }
    if (onCompleted) {
      onCompleted(newSummary);
    }

    setSelectedOrganization(newSummary);
  };

  const match = useRouteMatch<RouteParams>('/organizations/:organizationName');

  const lastOrgName = orgName;

  let urlOrgName = match?.params?.organizationName;
  if (urlOrgName) {
    urlOrgName = decodeURIComponent(urlOrgName);
  }

  const hasAccessToLastOrgName =
    isAdmin || account?.managedOrgs?.some((o) => o.org.name === lastOrgName);

  useEffect(() => {
    if (isAdmin || isNonAdminWithOrgs || isDM) {
      if (isOrgUndefined && urlOrgName && hasAccessToSelectedOrgName) {
        setOrgByName(urlOrgName);
      } else if (isOrgUndefined && account?.submissionCreateOrgs?.[0]?.name) {
        setOrgByName(account?.submissionCreateOrgs?.[0]?.name);
      } else if (
        isOrgUndefined &&
        lastOrgName !== 'Select Organization' &&
        !!lastOrgName &&
        hasAccessToLastOrgName
      ) {
        setOrgByName(lastOrgName);
      } else if (isOrgUndefined && account?.managedOrgs?.[0]?.org?.name) {
        setOrgByName(account?.managedOrgs?.[0]?.org?.name);
      } else if (!hasAccessToSelectedOrgID) {
        setOrgByName(account?.managedOrgs[0]?.org?.name);
      } else if (
        isOrgUndefined ||
        selectedOrganization?.name === 'Select Organization' ||
        lastOrgName === 'Select Organization'
      ) {
        getOrgs();
        const firstOrg = data?.projects
          ? data?.projects?.[0]?.orgName
          : data?.organizationsPage?.organizations?.[0]?.name;
        if (firstOrg) {
          setOrgByName(firstOrg);
        }
      }
    }
  }, [selectedOrganization, loading]);

  return (
    <UserSessionContext.Provider
      value={{ selectedOrganization, setOrgByName, setSelectedOrganization }}
    >
      {typeof children === 'function'
        ? children({ selectedOrganization, setOrgByName, setSelectedOrganization })
        : children}
    </UserSessionContext.Provider>
  );
};

export const useUserSession = () => {
  const context = useContext(UserSessionContext);
  return context;
};
