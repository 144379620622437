import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Comparators,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPageHeader,
  EuiPanel,
  EuiText,
  EuiTitle,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { RecommendationType } from '@app/graphql/precheck/precheck.types';
import { useGetTasksQuery } from '@app/graphql/precheck/queries/getTasks/__generated__/getSingleTask.generated';
import { handlePDFExport } from '@app/utils/pdfExport';
import { exportReasonLabelMap } from '../Components/V2/constants/labelMap';
import { useTaskContext } from '../Components/V2/context/TaskContext';
import { IFormattedtask } from '../Components/V2/context/types';
import { formatTasksAndOptions } from '../Components/V2/context/utils/formatTaskAndOptions';
import {
  getCurrencyValue,
  lineItemNameIds,
  subValues,
} from '../Components/ValuationOutlier/ValuationOutlier';
import { usePrecheckClient } from '../hooks/usePrecheckClient';
import {
  ExplanationContainer,
  LinkContainer,
  PrecheckCard,
  PrecheckRecommendationsExportContainer,
  Reasons,
  ReasonsContainer,
  SummaryContainer,
  TableContainer,
  Title,
  TitleContainer,
} from './PrecheckRecommendationsExport.emotion';

export const PrecheckRecommendationsExport = () => {
  const { selectedOrganization } = useUserSession();
  const client = usePrecheckClient();
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  //   @ts-ignore
  const currentDate = new Date().toLocaleDateString('en-US', options);
  const history = useHistory();
  const { sortField, sortDirection, selectedTasks, filters, filteredTiv, tivPercent } =
    useTaskContext();

  const contentRef = useRef();

  const [isDownloaded, setIsDownloaded] = useState(false);

  const { data, loading } = useGetTasksQuery({
    client,
    variables: {
      input: {
        orgName: selectedOrganization.name,
      },
    },
  });

  const { transformedTasks: tasks } = formatTasksAndOptions(data?.getTasks.tasks);

  useEffect(() => {
    if (tasks && !isDownloaded && !loading && !tivPercent.includes('NaN')) {
      const input = contentRef.current;
      handlePDFExport(input, 'precheck');
      setIsDownloaded(true);
    }
  }, [tasks, isDownloaded, loading, tivPercent]);

  let effectiveSortField = sortField;
  if (effectiveSortField === 'Property') {
    effectiveSortField = 'propertyName';
  }

  if (effectiveSortField === 'tiv') {
    effectiveSortField = 'rawTiv';
  }

  const items = sortField
    ? tasks
        .slice(0)
        .sort(Comparators.property(effectiveSortField, Comparators.default(sortDirection)))
    : tasks;

  const sorting = {
    sort: {
      direction: sortDirection,
      field: sortField,
    },
  };

  const valuationOutlierColumns = [
    {
      field: 'attributes',
      name: (
        <EuiTitle size="xxs">
          <p>Attribute</p>
        </EuiTitle>
      ),
      render: (_, attribute) => {
        const valueNode =
          attribute.attributeMetadata.name === 'buildingReplacementCost' ||
          attribute.attributeMetadata.name === 'replacementCostPerSquareFootage' ? (
            <b>{attribute.attributeMetadata.displayName}</b>
          ) : (
            attribute.attributeMetadata.displayName
          );
        return (
          <EuiFlexGroup gutterSize="none" direction="column">
            <EuiFlexItem grow={false}>{valueNode}</EuiFlexItem>
            {attribute.attributeMetadata.name === 'buildingReplacementCost' && (
              <ExplanationContainer grow={false}>
                Building value + Improvements / betterments + Misc. building replacement cost
              </ExplanationContainer>
            )}
            {attribute.attributeMetadata.name === 'replacementCostPerSquareFootage' && (
              <ExplanationContainer grow={false}>
                Building replacement cost ÷ Floor area
              </ExplanationContainer>
            )}
          </EuiFlexGroup>
        );
      },
    },
    {
      field: 'attributes',
      name: (
        <EuiTitle size="xxs">
          <p>Current Value</p>
        </EuiTitle>
      ),
      render: (_, attribute) => {
        const value = attribute.property?.[attribute.attributeMetadata.name];

        const effectiveValue =
          attribute.attributeMetadata.dataType === 'currencyLocal'
            ? getCurrencyValue({
                currency: 'USD',
                value,
              })
            : `${new Intl.NumberFormat('en-US').format(value || 0)} sq. ft.`;

        const valueNode =
          attribute.attributeMetadata.name === 'buildingReplacementCost' ||
          attribute.attributeMetadata.name === 'replacementCostPerSquareFootage' ? (
            <b>{effectiveValue}</b>
          ) : (
            effectiveValue
          );

        return (
          <EuiFlexGroup gutterSize="none" direction="column">
            <EuiFlexItem grow={false}>{valueNode}</EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
  ];

  const columns = [
    {
      field: 'attributes',
      name: (
        <EuiTitle size="xxs">
          <p>Missing Attribute</p>
        </EuiTitle>
      ),
      render: (_, attribute) => (
        <EuiFlexGroup gutterSize="none" direction="column">
          <EuiFlexItem grow={false}>{attribute.attributeMetadata.displayName}</EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
    {
      field: 'attributes',
      name: (
        <EuiTitle size="xxs">
          <p>Suggested Documents</p>
        </EuiTitle>
      ),
      render: (_, attribute) => (
        <EuiFlexGroup gutterSize="none" direction="column">
          <EuiFlexItem grow={false}>{attribute.documents.join(', ')}</EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
  ];

  if (loading) {
    return <LoadingSpinnerV2 />;
  }

  return (
    <PrecheckRecommendationsExportContainer ref={contentRef}>
      <EuiPanel>
        <EuiFlexGroup gutterSize="m" direction="column">
          <EuiPageHeader
            pageTitle={
              <EuiTitle size="m">
                <p>{`Recommendations to Improve ${selectedOrganization?.name}'s Submission`}</p>
              </EuiTitle>
            }
            rightSideItems={[
              <EuiText key="rightSide" size="s">
                <p>{currentDate}</p>
              </EuiText>,
            ]}
          />
          <EuiFlexItem>
            <LinkContainer
              data-hide-print="true"
              onClick={() => {
                history.push({
                  pathname: encodeUrl`/organizations/${selectedOrganization?.name}/precheck`,
                });
              }}
            >
              <EuiText size="s">View all recommendations on Archipelago’s platform</EuiText>
            </LinkContainer>
          </EuiFlexItem>
          <SummaryContainer>
            <EuiText size="s">
              Showing recommendations for <b>{tasks.length} </b>
              properties representing <b>{tivPercent}</b> ({filteredTiv}) of TIV
            </EuiText>
          </SummaryContainer>
          <EuiFlexItem>
            <EuiTitle size="xxs">
              <p>Filters applied:</p>
            </EuiTitle>
          </EuiFlexItem>
          {filters.reasons.length > 0 && (
            <ReasonsContainer>
              <EuiTitle size="xxs">
                <p>Recommendation reason:</p>
              </EuiTitle>
              <EuiText size="s">
                <Reasons>{filters.reasons.join(', ')}</Reasons>
              </EuiText>
            </ReasonsContainer>
          )}
          {filters.name && (
            <SummaryContainer>
              <b>Keyword: </b>
              {filters.name}
            </SummaryContainer>
          )}
          {tasks.map((task: IFormattedtask, index) => {
            const missingAttributes = items[index].attributes.filter(
              (attribute) => attribute.recommendationType === RecommendationType.MissingAttribute,
            );

            const valuationOutliers = items[index].attributes.filter(
              (attribute) => attribute.recommendationType === RecommendationType.ValuationOutlier,
            );

            const totalBuildingReplacementCost =
              task.property?.buildingReplacementCost ||
              subValues.reduce((acc, lineItemId) => acc + (task.property[lineItemId] || 0), 0) ||
              0;

            const reasons = task.reasons
              .map((reason) => exportReasonLabelMap[reason])
              .filter((reason) => reason);

            return (
              <EuiFlexItem key={task.id}>
                <TitleContainer>
                  <Title size="xxxs">
                    <p>#{index + 1} Priority</p>
                  </Title>
                </TitleContainer>
                {/* @ts-ignore */}
                <PrecheckCard>
                  <EuiFlexGroup justifyContent="flexStart">
                    <EuiFlexGroup justifyContent="flexStart">
                      <EuiTitle size="xs">
                        <p>
                          {task.property.streetAddress}, {task.property.city}, {task.property.state}
                        </p>
                      </EuiTitle>
                    </EuiFlexGroup>
                    <EuiFlexGroup justifyContent="center">
                      <EuiTitle size="xxs">
                        <p>{task.tiv}</p>
                      </EuiTitle>
                    </EuiFlexGroup>
                    <EuiFlexGroup justifyContent="flexEnd">
                      <EuiTitle size="xxs">
                        {task.assignees.length === 1 ? (
                          <p>Assigned to {task.assignees[0].name}</p>
                        ) : task.assignees.length > 1 ? (
                          <p>
                            Assigned to {task.assignees[0].name} and {task.assignees.length - 1}{' '}
                            {task.assignees.length - 1 === 1 ? 'other' : 'others'}
                          </p>
                        ) : (
                          <p>Unassigned</p>
                        )}
                      </EuiTitle>
                    </EuiFlexGroup>
                  </EuiFlexGroup>
                  {missingAttributes.length > 0 && (
                    <TableContainer>
                      <EuiFlexGroup justifyContent="flexStart">
                        <EuiText size="s">
                          <p>
                            This property is missing key data related to:{' '}
                            <b>
                              {reasons.length === 1
                                ? reasons[0]
                                : reasons.slice(0, -1).join(', ') +
                                  ' and ' +
                                  reasons[reasons.length - 1]}
                              .
                            </b>{' '}
                            See the summary of missing attributes, and documents where they might be
                            found, below.
                          </p>
                        </EuiText>
                      </EuiFlexGroup>
                      <EuiFlexGroup justifyContent="flexStart">
                        <EuiBasicTable
                          key={selectedTasks.length}
                          data-testid="precheck-missing-attributes-table"
                          //   @ts-ignore
                          columns={columns}
                          items={missingAttributes}
                          sorting={sorting}
                        />
                      </EuiFlexGroup>
                    </TableContainer>
                  )}
                  {valuationOutliers.length > 0 && (
                    <TableContainer marginTop={missingAttributes.length ? '24px' : undefined}>
                      <EuiFlexGroup justifyContent="flexStart">
                        <EuiText size="s">
                          <p>
                            Our models have identified this property as potentially undervalued.{' '}
                            <b>
                              We recommend getting an appraisal to confirm the current valuation.
                            </b>{' '}
                            The current valuations are summarized below.
                          </p>
                        </EuiText>
                      </EuiFlexGroup>
                      <EuiFlexGroup justifyContent="flexStart">
                        <EuiBasicTable
                          key={selectedTasks.length}
                          data-testid="precheck-valuation-outliers-table"
                          //   @ts-ignore
                          columns={valuationOutlierColumns}
                          items={lineItemNameIds.reduce((acc, lineItemId) => {
                            const item = valuationOutliers.find(
                              //@ts-ignore
                              (attributes) => attributes.attributeMetadata.name === lineItemId,
                            );
                            if (lineItemId === 'buildingReplacementCost') {
                              return [
                                ...acc,
                                {
                                  attributeMetadata: {
                                    dataType: 'currencyLocal',
                                    displayName: 'Building replacement cost',
                                    name: 'buildingReplacementCost',
                                  },
                                  name: lineItemId,
                                  property: {
                                    ...task.property,
                                    buildingReplacementCost: totalBuildingReplacementCost,
                                  },
                                },
                              ];
                            }
                            if (!item) {
                              return acc;
                            }
                            return [...acc, { ...item, property: task.property }];
                          }, [])}
                          sorting={sorting}
                        />
                      </EuiFlexGroup>
                    </TableContainer>
                  )}
                </PrecheckCard>
              </EuiFlexItem>
            );
          })}
        </EuiFlexGroup>
      </EuiPanel>
    </PrecheckRecommendationsExportContainer>
  );
};
