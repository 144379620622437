import { FC, ReactNode, useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Sort, SortOrder } from '@onarchipelago/cx/Reports/queries/getCarrierPropertyClaims';
import ProvenancePropertySelect from '@onarchipelago/cx/SmartFolder/components/RelationshipSelect/ProvenancePropertySelect';
import RelationshipSelectFlyout, {
  Label,
} from '@onarchipelago/cx/SmartFolder/components/RelationshipSelect/RelationshipSelectFlyout';
import SearchInput from '@onarchipelago/cx/SmartFolder/components/RelationshipSelect/SearchInput';
import { EPropertySelectType } from '@onarchipelago/dice/EnrichmentProjects/types';
import { ButtonIcon, EuiFlexGroup, EuiFlexItem, EuiFlyoutHeader, EuiText } from 'ui';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { AttributeFilter } from '@app/graphql/types';
// FIX ME
// @ts-ignore
import GET_ORGANIZATION_PROPERTY_GROUPS from '@app/queries/explorers/getOrganizationPropertyGroups.gql';
// FIX ME
// @ts-ignore
import GET_STREAM_PROPERTY_GROUPS from '@app/queries/explorers/getStreamPropertyGroups.gql';
// FIX ME
// @ts-ignore
import GET_DELETED_PROPERTIES from '@app/queries/properties/getDeletedProperties.gql';
// FIX ME
// @ts-ignore
import GET_DISPOSED_PROPERTIES from '@app/queries/properties/getDisposedProperties.gql';
// FIX ME
// @ts-ignore
import GET_PROPERTIES from '@app/queries/properties/getProperties.gql';
// FIX ME
// @ts-ignore
import GET_PROPERTIES_WITH_LOSSES from '@app/queries/properties/getPropertiesWithLosses.gql';
// FIX ME
// @ts-ignore
import GET_PROPERTIES_FOR_ENRICHMENT from '@app/queries/properties/propertiesForEnrichment.gql';
import { IGraphQLProperty, PropertiesPageResponse } from '@app/queries/properties/types';

// TODO replace IGraphQLProperty with IGraphQLPropertySlim
// import { IGraphQLPropertySlim } from './types';

export const getQuery = (propertySelectType: EPropertySelectType) => {
  switch (propertySelectType) {
    case EPropertySelectType.EnrichableProperties:
      return GET_PROPERTIES_FOR_ENRICHMENT;
    case EPropertySelectType.AllPropertiesWithLosses:
      return GET_PROPERTIES_WITH_LOSSES;
    case EPropertySelectType.DisposedProperties:
      return GET_DISPOSED_PROPERTIES;
    case EPropertySelectType.DeletedProperties:
      return GET_DELETED_PROPERTIES;
    case EPropertySelectType.AllProperties:
      return GET_PROPERTIES;
  }
};

interface queryVariables {
  cursor: string;
  filter: Array<AttributeFilter>;
  groupID: string;
  orgID: string;
  orgName: string;
  pageSize: number;
  projectName?: string;
  propertySelectType: EPropertySelectType;
  sortBy: Array<Sort>;
}
export const getVariables = ({
  cursor,
  filter,
  groupID,
  orgID,
  orgName,
  pageSize,
  projectName,
  propertySelectType,
  sortBy,
}: queryVariables) => {
  switch (propertySelectType) {
    case EPropertySelectType.EnrichableProperties:
      return {
        filter,
        orgName,
        projectName,
        sortBy,
      };
    case EPropertySelectType.AllPropertiesWithLosses:
      return {
        input: {
          cursor,
          filter,
          orgName,
          pageSize,
          sortBy,
        },
      };
    case EPropertySelectType.DisposedProperties:
      return {
        input: {
          cursor,
          filter,
          orgID,
          pageSize,
        },
      };
    case EPropertySelectType.DeletedProperties:
      return {
        input: {
          cursor,
          filter,
          orgID,
          pageSize,
        },
      };
    case EPropertySelectType.AllProperties:
      return {
        cursor,
        groupID,
        pageSize,
        sortBy,
      };
    default:
      throw new Error(`${propertySelectType} not implemented`);
  }
};

const filterOptions = [
  { text: 'Location Name', value: 'locationName' },
  { text: 'City', value: 'city' },
  { text: 'State', value: 'state' },
];

interface Props {
  filterable: any;
  footer?: ReactNode;
  hide?: () => void;
  isDittoDisabled?: boolean;
  multiple?: boolean;
  orgID?: string;
  orgName: string;
  projectName?: string;
  /**
   * propertySelectType is an enum that let's you pick which set of properties should be shown in
   * the properties list.
   */
  propertySelectType: EPropertySelectType;
  rowComponent: any;
  selectedItems: IGraphQLProperty[];
  // streamSlug: The slug of the stream. When specified we query the properties on stream level instead of the default org level.
  streamSlug?: string;
  title?: string;
  withProvenance?: boolean;
  updateSelectedProperties: (updatedProperties: IGraphQLProperty[]) => void;
}

const PAGE_SIZE = 100000000;
const SelectPropertiesFlyout: FC<Props> = ({
  filterable,
  hide,
  isDittoDisabled,
  multiple,
  orgID,
  orgName,
  projectName,
  propertySelectType,
  rowComponent,
  selectedItems,
  streamSlug,
  title,
  withProvenance,
  updateSelectedProperties,
}) => {
  const jobsApolloClient = useJobsApolloClient();
  const [onlyShowSelected, setOnlyShowSelected] = useState<boolean>(false);

  const [searchFilter] = useState(filterOptions[0].value);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState([]);
  const [selectVisible, setSelectVisible] = useState<boolean>(false);

  const cursor = '';
  const searchFilterText =
    searchText && searchText.length > 0
      ? [{ name: searchFilter || 'locationName', operator: 'CONTAINS', values: [searchText] }]
      : [];

  const onError = useCallback((e) => console.warn('Error callback in Properties Select', e), []);

  const sortBy: Array<Sort> = [
    { attributeName: 'locationName', order: SortOrder.ASCENDING },
    { attributeName: 'streetAddress', order: SortOrder.ASCENDING },
    { attributeName: 'city', order: SortOrder.ASCENDING },
  ];

  const { loading: loadingGroups, data: orgGroupsData } = useQuery(
    !!streamSlug ? GET_STREAM_PROPERTY_GROUPS : GET_ORGANIZATION_PROPERTY_GROUPS,
    {
      onError,
      skip: propertySelectType == EPropertySelectType.AllPropertiesWithLosses,
      variables: {
        filter: [...filters, ...searchFilterText],
        groupBy: undefined,
        organizationName: orgName,
        streamSlug: !!streamSlug ? streamSlug : undefined,
      },
    },
  );

  // The streamPropertyGroups and organizationPropertyGroups have their data returned differently.
  // This function is to help pick out the groupID correctly.
  const getGroupId = () => {
    if (!!streamSlug) {
      return orgGroupsData?.streamPropertyGroups?.groups?.groupID;
    }
    return orgGroupsData?.organizationPropertyGroups?.groupID;
  };

  const query = getQuery(propertySelectType);
  const queryVariables = getVariables({
    cursor,
    filter: [...filters, ...searchFilterText],
    groupID: getGroupId(),
    orgID,
    orgName,
    pageSize: PAGE_SIZE,
    projectName,
    propertySelectType,
    sortBy,
  });

  const { loading, data, fetchMore } = useQuery(query, {
    ...(propertySelectType === EPropertySelectType.EnrichableProperties && {
      client: jobsApolloClient,
    }),
    onError,
    skip: propertySelectType != EPropertySelectType.AllPropertiesWithLosses && !getGroupId(),
    variables: queryVariables,
  });

  const onQueryRun = (formattedFilters: any) => {
    setFilters(formattedFilters);
  };

  const loadMore = () =>
    fetchMore?.({
      updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any; rest: any }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...fetchMoreResult,
          propertiesPage: {
            ...fetchMoreResult.propertiesPage,
            properties: [
              ...prev.propertiesPage.properties,
              ...fetchMoreResult.propertiesPage.properties,
            ],
          },
        };
      },
      variables:
        propertySelectType == EPropertySelectType.AllPropertiesWithLosses
          ? {
              input: {
                cursor: data?.propertiesPage?.pageInfo?.cursor,
                filter: [...filters, ...searchFilterText],
                orgName,
                pageSize: 100,
                sortBy,
              },
            }
          : {
              cursor: data?.propertiesPage?.pageInfo?.cursor,
            },
    });

  const itemHeight = (item: IGraphQLProperty) => {
    const oneLine = 45;
    const twoLines = 67;
    const threeLines = 89;
    const fourLines = 110;

    if (!item) {
      return threeLines;
    }

    const { locationName, streetAddress, documents } = item;

    if (!locationName || !streetAddress) {
      return twoLines;
    }
    if (locationName === streetAddress) {
      return twoLines;
    }
    if (locationName !== streetAddress) {
      if (documents?.length > 0) {
        return fourLines;
      }
      return threeLines;
    }

    return oneLine;
  };

  const getPropertiesData = (propertySelectType: EPropertySelectType) => {
    switch (propertySelectType) {
      case EPropertySelectType.EnrichableProperties:
        return data?.propertiesForEnrichment || [];
      case EPropertySelectType.DisposedProperties:
        return data?.disposedProperties?.properties || [];
      case EPropertySelectType.DeletedProperties:
        return data?.deletedProperties?.properties || [];
      case EPropertySelectType.AllProperties:
        return (data as PropertiesPageResponse)?.propertiesPage?.properties;
      case EPropertySelectType.AllPropertiesWithLosses:
        return (data as PropertiesPageResponse)?.propertiesPage?.properties;
    }
  };

  const propertiesData: IGraphQLProperty[] = getPropertiesData(propertySelectType);

  const visibleProperties =
    propertySelectType == EPropertySelectType.AllPropertiesWithLosses ||
    propertySelectType == EPropertySelectType.DisposedProperties ||
    propertySelectType == EPropertySelectType.DeletedProperties
      ? propertiesData
      : propertiesData?.filter(
          (p: IGraphQLProperty) =>
            p.propertyStatus !== 'Disposed' && p.propertyStatus !== 'Superceded',
        ) || [];

  const propertiesOmittingDisposed = {
    propertiesPage: {
      properties: visibleProperties,
    },
  };

  const onlyDisposedProperties = {
    propertiesPage: {
      properties: data?.disposedProperties?.properties,
    },
  };

  const onlyDeletedProperties = {
    propertiesPage: {
      properties: data?.deletedProperties?.properties,
    },
  };

  const getPropertyList = (propertySelectType: EPropertySelectType) => {
    switch (propertySelectType) {
      case EPropertySelectType.DisposedProperties:
        return onlyDisposedProperties;
      case EPropertySelectType.DeletedProperties:
        return onlyDeletedProperties;
      default:
        return propertiesOmittingDisposed;
    }
  };

  const propertyList = getPropertyList(propertySelectType);
  const renderLabel = () => {
    if (multiple) {
      return (
        <Label>
          {selectedItems.length === 0 && 'All selected'}
          {selectedItems.length >= 1 && `${selectedItems.length} selected`}
        </Label>
      );
    }

    if (selectedItems.length === 0) {
      return <Label>Select property</Label>;
    }

    return <Label>{selectedItems[0].locationName}</Label>;
  };

  const selectAllVisible = () => {
    setSelectVisible(!selectVisible);

    if (!selectVisible) {
      updateSelectedProperties(visibleProperties);
    } else {
      updateSelectedProperties([]);
    }
  };

  return (
    <>
      <EuiFlyoutHeader>
        <EuiFlexGroup justifyContent="flexStart">
          {hide && (
            <EuiFlexItem grow={false} style={{ alignItems: 'center', justifyContent: 'center' }}>
              <ButtonIcon
                data-testid="select-properties-back"
                iconName="arrowLeft"
                size="m"
                onClick={hide}
                aria-label="Back"
                color="text"
              />
            </EuiFlexItem>
          )}
          <EuiFlexItem style={{ marginLeft: hide ? '0px' : '' }}>
            <EuiText>
              <h2>{title || 'Select Properties'}</h2>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutHeader>
      {!isDittoDisabled && withProvenance ? (
        <ProvenancePropertySelect
          filterable={filterable}
          relationship="properties"
          query={{
            loadMore,
            loading: loading || loadingGroups,
            propertyList,
          }}
          item={rowComponent}
          dataPath={'propertyList.propertiesPage.properties'}
          hasMore={data?.propertiesPage?.pageInfo?.cursor}
          formHeader={
            <>
              <SearchInput
                multiple={multiple}
                onlyShowSelected={onlyShowSelected}
                placeholder="Search Properties"
                searchText={searchText}
                setOnlyShowSelected={setOnlyShowSelected}
                setSearchText={setSearchText}
                searchType="properties"
                selectAll={selectAllVisible}
                selectVisible={selectVisible}
                withProvenance={withProvenance}
              />
            </>
          }
          formFooter={null}
          itemHeight={itemHeight}
          selectedItems={selectedItems}
          onlyShowSelected={onlyShowSelected}
          type="property"
          renderLabel={renderLabel}
          onQueryRun={onQueryRun}
          updateSelectedProperties={updateSelectedProperties}
        />
      ) : (
        <RelationshipSelectFlyout
          filterable={filterable}
          relationship="properties"
          query={{
            loadMore,
            loading: loading || loadingGroups,
            propertiesOmittingDisposed,
          }}
          item={rowComponent}
          dataPath={'propertiesOmittingDisposed.propertiesPage.properties'}
          hasMore={data?.propertiesPage?.pageInfo?.cursor}
          formHeader={
            <>
              <SearchInput
                multiple={multiple}
                onlyShowSelected={onlyShowSelected}
                placeholder="Search Properties"
                searchText={searchText}
                setOnlyShowSelected={setOnlyShowSelected}
                setSearchText={setSearchText}
                searchType="properties"
                selectAll={selectAllVisible}
                selectVisible={selectVisible}
                withProvenance={withProvenance}
              />
            </>
          }
          formFooter={null}
          itemHeight={itemHeight}
          selectedItems={selectedItems}
          onlyShowSelected={onlyShowSelected}
          type="property"
          renderLabel={renderLabel}
          onQueryRun={onQueryRun}
        />
      )}
    </>
  );
};

export default SelectPropertiesFlyout;
