import { useUserSession } from '@app/contexts/UserSessionContext';
import { StreamProvider } from '@app/cx/Stream/StreamProvider';
import { useOrgInfoQuery } from '@app/graphql/queries/organization/info/__generated__/getOrgInfo.generated';
import { EnterpriseSpotlightPage } from '../EnterpriseSpotlightPage/EnterpriseSpotlightPage';
import { Spotlight } from '../Spotlight/Spotlight';

export const SpotLightContainer = () => {
  const { selectedOrganization } = useUserSession();

  const { data: orgInfoData, loading } = useOrgInfoQuery({
    variables: {
      input: {
        orgName: selectedOrganization?.name,
      },
    },
  });

  if (loading) {
    return null;
  }

  const isEnterpriseAccount = orgInfoData?.orgInfo?.orgInfo?.orgLevel === 'Enterprise';
  return isEnterpriseAccount ? (
    <EnterpriseSpotlightPage />
  ) : (
    <StreamProvider>
      <Spotlight />
    </StreamProvider>
  );
};
